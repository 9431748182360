.input-checkbox > .chakra-form-control > div:last-child > div > span > [data-testid="close#"] {
  background-image: url("./close.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

.input-checkbox > .chakra-form-control > div:last-child > div > span > [data-testid="check-done#"] {
  background-image: url("./check-done.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
